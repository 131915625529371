import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import { lead_columns } from "../../../components/tables/tableheader";
import {
  getAllUsers,
} from "../../../redux/user/actions";

import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  exportToExcel,
} from "../../../helper/commonHelper";

import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MasterServices from "../../../ApiServices/MasterServices";

import { toast } from "react-toast";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import queryString from "query-string";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function LeadList() {
  const pages = [{ title: "Lead List", href: "/lead-list", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const {
    user: { loading: userLoading, list: userList },
  } = useSelector((state) => state);


  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [filteredData, setfilteredData] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [productLeadList, setProductLeadList] = useState([]);
  const [userLeadList, setUserLeadList] = useState([]);
  let mutableFilteredUsersData = leadList;

  console.log(filteredData);

  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 500,
    // isActiveFilter: 1,
    // isDeletedFilter: 0,
    commonFilter: '',
    productFilter: '',
    userFilter: '',
    startDate: '',
    endDate: '',
  });
  //console.log(filters);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    pulittw_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log(userList);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const [pathname, setPathname] = useState("");
  let User_ACtive_Tab = 0;
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    setPathname(window.location.pathname);
    let payload = {
      user_is_hr_profesional: User_ACtive_Tab === 1 ? 1 : 2,
      user_is_hr_source_provider: User_ACtive_Tab === 2 ? 1 : 2,
      is_all_user: User_ACtive_Tab === 0 ? 1 : 2,
      user_is_cms: User_ACtive_Tab === 3 ? 1 : 2,
      user_is_active: 3,
    };
    dispatch(getAllUsers(payload));
    getAllInterestLeadList()
    getAllLeadProductList(0);
    getAllLeadUserList(0);
  }, []);

  const getAllInterestLeadList = async () => {
    setLeadList([]);
    setfilteredData([]);
    await MasterServices.getAllInterestLeadProd(filters)
      .then((res) => {
        const { data, status, meta } = res;
        console.log("PS=>", data?.data?.data);
        if (Array.isArray(data?.data?.data) && data?.data?.data.length > 0) {

          setLeadList(data?.data?.data);
          setfilteredData(data?.data?.data);
          setPagination(data?.data?.pagination)
        } else {
          setLeadList([]);
          setfilteredData([]);
          setPagination([]);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };

  const getAllLeadProductList = async (item) => {
    setProductLeadList([]);
    let user_id = (item > 0 ? item : (filters?.userFilter?.value || 0));
    await MasterServices.getAllLeadProduct({ user_id: user_id })
      .then((res) => {
        const { data, status, meta } = res;
        console.log("getAllLeadProductList=>", data?.data);
        if (Array.isArray(data?.data) && data?.data.length > 0) {
          setProductLeadList(data?.data);
        } else {
          setProductLeadList([]);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };

  const getAllLeadUserList = async (item) => {
    setUserLeadList([]);
    let product_id = (item > 0 ? item : (filters?.productFilter?.value || 0));
    await MasterServices.getAllLeadUser({ product_id: product_id })
      .then((res) => {
        const { data, status, meta } = res;
        console.log("getAllLeadUserList=>", data?.data);
        if (Array.isArray(data?.data) && data?.data.length > 0) {
          setUserLeadList(data?.data);
        } else {
          setUserLeadList([]);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    const userData = JSON.parse(localStorage.getItem("user"));

    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);


  useEffect(() => {
    document.title = "Octopus | Leads";

  }, []);

  const onDeleteOpen = (pulittw_id, pulittw_is_active) => {
    setConfirmationModal({ pulittw_id, pulittw_is_active, status: true });
  };

  const onDeleteUser = (pulittw_id) => {
    setConfirmationModal({ pulittw_id: null, status: false });
  };


  useEffect(() => {

    if (filters.role) {
      if (filters.role === "all_users") {

        mutableFilteredUsersData = [];
      } else if (filters.role === "frontend") {
        mutableFilteredUsersData = mutableFilteredUsersData.filter(
          (user) =>
            user?.m_role_relation &&
            (user?.m_role_relation?.role_id === 5 ||
              user?.m_role_relation?.role_id === 6)
        );
      } else {
        mutableFilteredUsersData = mutableFilteredUsersData.filter((user) => {
          return user?.m_role_relation &&
            user?.m_role_relation?.role_name === filters?.role;
        });
      }
    }

    if (filters.startDate || filters.endDate) {
      const startDate = filters.startDate
        ? new Date(filters.startDate)
        : new Date("1000-01-01");
      const endDate = filters.endDate ? new Date(filters?.endDate) : new Date();

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      mutableFilteredUsersData = mutableFilteredUsersData.filter((user) => {
        const userCreatedDate = new Date(user?.user_created_at);
        return userCreatedDate >= startDate && userCreatedDate <= endDate;
      });
    }

    setfilteredData(mutableFilteredUsersData);
  }, [filters, userLeadList]);

  // Call getAllInterestLeadList whenever filters change
  useEffect(() => {
    getAllInterestLeadList();
    // getAllLeadProductList(0);
    // getAllLeadUserList(0);
  }, [filters]); // This will be triggered when filters change


  const clearForm = () => {
    setEndDate("");
    setStartDate("");
    setFilters({
      page: 1,
      pageSize: 500,
      // isActiveFilter: 1,
      // isDeletedFilter: 0,
      commonFilter: '',
      productFilter: '',
      userFilter: '',
      startDate: '',
      endDate: '',
    });
  };

  const downloadUserDataExcel = () => {
    const dataToExport = filteredData;

    // Utility functions for data formatting
    const capitalise = (str) => {
      return (str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()) ?? '';
    };

    const formatDate = (dateString) => {
      if (!dateString) {
        return "----";
      }
      return new Date(dateString).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
    };

    const columns = [
      {
        Header: "ID",
        accessor: "pulittw_id",
      },
      {
        Header: "Product Name",
        accessor: (d) => capitalise(d?.pulittw_m_products_relation?.ser_prod_name),
      },
      {
        Header: "Lead Date",
        accessor: (d) => formatDate(d.pulittw_created_at),
      },
      {
        Header: "Name",
        accessor: (d) => capitalise(d?.pulittw_m_user_relation?.user_first_name || "") + " " + capitalise(d?.pulittw_m_user_relation?.user_last_name || ""),
      },
      {
        Header: "Email",
        accessor: (d) => capitalise(d?.pulittw_m_user_relation?.user_email),
      },
      {
        Header: "Mobile",
        accessor: (d) => (d?.pulittw_m_user_relation?.user_mobile),
      },
      {
        Header: "Role",
        accessor: (d) => capitalise(d?.pulittw_m_user_relation?.m_role_relation?.role_name),
      },
      {
        Header: "Designation",
        accessor: (d) => capitalise(d?.pulittw_m_user_relation?.user_designation),
      },
      {
        Header: "Company",
        accessor: (d) => capitalise(d?.pulittw_m_user_relation?.user_company_name),
      },
      {
        Header: "Organization Size",
        accessor: (d) => (d?.pulittw_m_user_relation?.user_organisation_size),
      },

      // {
      //   Header: "Total Views",
      //   accessor: (d) => (d?.pulittw_prod_user_lead_interest_track_map_relation?.pulit_view_count),
      // },
      // {
      //   Header: "Time wise Views",
      //   accessor: (d) => (d?.pulittw_view_count),
      // },


    ];

    // Prepare data for Excel export
    const headings = [columns.map(col => col.Header)];
    const transformedData = dataToExport.map(item => {
      const row = {};
      columns.forEach(col => {
        const value = typeof col.accessor === "function" ? col.accessor(item) : item[col.accessor];
        row[col.Header] = value || '';
      });
      return row;
    });

    // Assuming you have an `exportToExcel` function similar to before
    exportToExcel(transformedData, headings, "LeadsExport.xlsx", "Users");
  };


  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.pulittw_is_active ? "Disable " : "Activate") +
          " this User?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onDeleteUser(
            confirmationModal.pulittw_id,
            confirmationModal.pulittw_is_active
          )
        }
        setOpen={setConfirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className=" flex items-center justify-between my-2">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">Lead List</h1>
        </div>

      </div>
      <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={startDate}
                onChange={(e) => {
                  const startDate = e.target.value;
                  setStartDate(startDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    startDate: startDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="flex">
              <input
                type="date"
                name="date"
                value={endDate}
                onChange={(e) => {
                  const endDate = e.target.value;
                  setEndDate(endDate);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    endDate: endDate,
                  }));
                }}
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Users
            </label>
            <div>
              <Select
                classNamePrefix="select"
                defaultValue={{ label: "Select", value: "" }}
                placeholder="Select"
                value={selectedUser ? selectedUser : { label: "Select", value: "" }}
                name="userStatus"
                className="basic-single sm:text-sm"
                options={userLeadList}
                onChange={(selectedOption) => {
                  setSelectedUser(selectedOption);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    userFilter: selectedOption.value,
                  }));
                  getAllLeadProductList(selectedOption.value);
                }}
              />{" "}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product
            </label>
            <div>
              <Select
                classNamePrefix="select"
                defaultValue={{ label: "Select", value: "" }}
                placeholder="Select"
                value={selectedProduct ? selectedProduct : { label: "Select Product", value: "" }}
                name="userStatus"
                className="basic-single sm:text-sm"
                options={productLeadList}
                onChange={(selectedOption) => {
                  setSelectedProduct(selectedOption);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    productFilter: selectedOption.value,
                  }));
                  getAllLeadUserList(selectedOption.value);
                }}
              />{" "}
            </div>
          </div>

          {/* <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Common Filter
            </label>
            <div>
              <input
                type="text"
                placeholder="Enter a Common Filter"
                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  const commonFilterValue = e.target.value;
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    commonFilter: commonFilterValue,
                  }));
                  getAllInterestLeadList();
                }}
              />
            </div>
          </div> */}
        </div>
        <div className=" pt-4 ">
          <button
            onClick={clearForm}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Clear
          </button>

          {leadList?.length > 0 && (
            <button
              type="button"
              onClick={downloadUserDataExcel}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2"
            >
              Download Excel
            </button>)}
        </div>
      </div>
      <div className=" w-full mt-4 flex justify-end">

      </div>
      <div className="mt-4 flex gap-x-2 items-center ">

        <div className="w-[600px]">

        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={lead_columns({ onDeleteOpen, allowedActions })}
          data={filteredData ? filteredData : leadList}

        />
      )}

    </div>
  );
}
export default LeadList;
